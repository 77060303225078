<template>
    <b-col class="form-group col-md-3 d-flex align-items-center">
        <slot></slot>
    </b-col>
</template>
<script>
export default {
    name: 'CreateCol3',
}
</script>
